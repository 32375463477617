import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { animated, useChain, useSpring, useSpringRef } from 'react-spring'
import Input from './Input'
import LetterBody from './LetterBody'
import LetterTop from './LetterTop'
import Button from '../Button'
import { Trans } from 'react-i18next'

const Wrapper = styled.div`
  position: relative;
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  #letter-body {
    left: 50%;
    z-index: 10;
    position: absolute;
    transform: translateX(-50%);
    width: 600vw;
    height: 380px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  #letter-top {
    left: 50%;
    transform: translateX(-50%);
    top: 2px;
    z-index: 11;
    position: absolute;
    width: 600vw;
    height: 190px;
    svg {
      width: 100%;
      height: 100%;
      bottom: 0;
    }
  }
  #input {
    top: 0;
    padding: 15px 30px;
    z-index: 10;
    max-width: 760px;
    width: 100%;
  }
`

const Letter = styled.div`
  position: fixed;
  pointer-events: none;
  left: 50%;
  transform: translateX(-50%);
  width: 70vw;
  height: 100%;
  z-index: 11;
`

const Actions = styled.div`
  margin-top: 20px;
  display: flex;
  > :last-child {
    margin-left: auto;
  }
`

const LetterInput = () => {
  const letterTop = useSpringRef()
  const fullLetter = useSpringRef()
  const letterLeave = useSpringRef()
  const [opened, setOpened] = useState(false)
  const [left, setLeft] = useState(false)

  const topSpringProps = useSpring({
    to: {
      rotateX: opened && !left ? '180deg' : '0deg' 
    },
    ref: letterTop
  })
  const fullLetterProps = useSpring({
    to: {
      y: opened && !left ? '100vh' : '0',
      x: left ? '200%' : '0%'
    },
    ref: fullLetter
  })
  const letterLeaveProps = useSpring({
    to: {
      x: left ? '200%' : '0%'
    },
    ref: letterLeave
  })

  useChain((opened && !left) ? [letterTop, fullLetter] : [fullLetter, letterTop, letterLeave], [0, 0.5, left ? 1 : undefined].filter(d => d !== undefined) as number[])


  useEffect(() => {
    setTimeout(() => {
      setOpened(true)
    }, 500)
  }, [])


  const onSubmit = () => {
    setLeft(true)
  }

  return <Wrapper>
    <Letter>
      <animated.div style={{
        ...fullLetterProps,
        ...letterLeaveProps
      }}>
        <animated.div style={{
          transformOrigin: '50% 0%',
          transform: 'translateX(-50%)',
          ...topSpringProps
        }} id='letter-top'>
          <LetterTop />
        </animated.div>
        <animated.div id='letter-body'>
          <LetterBody />
        </animated.div>
      </animated.div>
    </Letter>
    <animated.div style={letterLeaveProps}>
    <Input id='input' />
    </animated.div>
    <Actions>
      <Button onClick={onSubmit}><Trans>Send</Trans></Button>
    </Actions>
  </Wrapper>
}

export default LetterInput