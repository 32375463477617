import React from 'react'

const LetterBody = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="980" height="418" viewBox="0 0 980 418">
    <g id="Group_93" data-name="Group 93" transform="translate(-526 -283)">
      <g id="Group_91" data-name="Group 91" transform="translate(4 -150)">
        <g id="Rectangle_256" data-name="Rectangle 256" transform="translate(522 433)" fill="#03aca1" stroke="#fff" stroke-width="7">
          <rect width="980" height="418" rx="10" stroke="none"/>
          <rect x="3.5" y="3.5" width="973" height="411" rx="6.5" fill="none"/>
        </g>
        <g id="Group_90" data-name="Group 90" transform="translate(97.287)">
          <path id="Path_28" data-name="Path 28" d="M-93.311,0H806.9L718.332,40.418H0Z" transform="translate(557.26 438.745)" fill="#fff"/>
        </g>
      </g>
    </g>
  </svg>
}

export default LetterBody
