import { graphql } from 'gatsby';
import React from 'react'
import { Trans } from 'react-i18next';
import styled from 'styled-components'
import LetterInput from '../components/LetterInput';
import BlueprintLayout from '../layouts/blueprint'

const Content = styled.div`
  position: relative;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  height: calc(100vh - 145px);
  width: 100%;
  h1 {
    text-transform: uppercase;
  }
`

const ContactPage = () => {
  return <BlueprintLayout>
    <Content>
      <h1><Trans>Drop me a message.</Trans></h1>
      <LetterInput />
    </Content>
  </BlueprintLayout>
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ContactPage
