import styled from 'styled-components';

export default styled.div`
  position: relative;
  font-size: 20px;
  padding: 8px 40px;
  color: #fff;
  background-color:  ${props => props.theme.accent};
  border-radius: 6px;
  cursor: pointer;
  :before {
    position: absolute;
    content: '';
    height: calc(100% - 6px);
    width: calc(100% - 6px);
    border: 1px solid #fff;
    top: 3px;
    left: 3px;
    border-radius: 6px;
    box-sizing: border-box;
  }
  
`