import styled from 'styled-components';

export default styled.textarea`
  width: 100%;
  background-color: ${props => props.theme.text}22;
  outline: none;
  border: 8px solid ${props => props.theme.text};
  resize: none;
  height: 380px;
  max-width: 780px;
  width: 100%;
  padding: 20px;
  font-size: 26px;
  font-family: 'Oswald';
  color: ${props => props.theme.text};
  border-radius: 12px;
`
