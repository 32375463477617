import React from 'react'

const LetterTop = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="972.618" height="216.365" viewBox="0 0 972.618 216.365">
    <g id="Group_92" data-name="Group 92" transform="translate(-279.78 -61.218)">
      <path id="Path_27" data-name="Path 27" d="M12819.525-1679.659l485.779,205.127,479-205.782Z" transform="translate(-12535.857 1741.532)" fill="#fff"/>
      <path id="Subtraction_1" data-name="Subtraction 1" d="M-11764.944,2209.583h0L-12256,2002.431l3.345-7.929h29.313l458.321,193.345,449.4-193.345h28.841l3.4,7.9-481.562,207.18Z" transform="translate(12535.781 -1932)" fill="rgba(255,255,255,0.5)"/>
    </g>
  </svg>
}

export default LetterTop
